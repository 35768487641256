html,
body,
#__next {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.home-slider-item {
  height: calc((100% - 30px) / 2) !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #EF8A23;
  border-left: 2px solid #ca741e;
  border-right: 2px solid #ca741e;
}


/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	border: none;
	box-shadow: 0 0 0 30px #252525 inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
	border: 0 none;
	outline: 0 none;
	-webkit-text-fill-color: #fff !important;
}